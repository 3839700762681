import React, { Component, useState, useEffect, useRef } from 'react'
import { ApiService, BASE_URL, PIXEL_URL } from '../services/ApiService'
import User from '../services/User'
import { useParams } from 'react-router-dom'
import 'grapesjs/dist/css/grapes.min.css'
import grapesjs from 'grapesjs'
import grapesjsMJML from 'grapesjs-mjml'
// import plugin from 'grapesjs-preset-newsletter'
import { v4 as uuidv4 } from 'uuid'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mSwal = withReactContent(Swal)

function withParams(Component) {
	return (props) => <Component {...props} params={useParams()} />
}

const MAIN_APP_BASE_URL = 'https://fairymail.cobaltfairy.com'

const NewEmailEditor = () => {
	const editorRef = useRef(null)

	useEffect(() => {
		const editor = grapesjs.init({
			container: '#email-editor',
			fromElement: true,
			avoidInlineStyle: false,
			plugins: [grapesjsMJML],
			pluginsOpts: {
				[grapesjsMJML]: {},
			},
			height: '300px',
		})
		console.log('editor.addComponents', editor.addComponents)
		editor.addComponents(`
        <mjml>
            <mj-body>
                <mj-section></mj-section>
            </mj-body>
        </mjml>
        `)
	}, [])

	return (
		<div>
			<div id="email-editor" />
		</div>
	)
}

export default NewEmailEditor
