import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { ApiService } from '../services/ApiService'
import User from '../services/User'
import LoadingContext from '../ToggleContext'

export class AccountChooser extends Component {
	static contextType = LoadingContext
	state
	constructor(props) {
		super(props)

		this.state = { accounts: [] }
	}
	componentDidMount = () => {
		this.context.setLoading_(false)
		let user = User.get()
		if (!user || !user.user_role || !user.jwt) {
			window.location.href = '/login'
			return
		}

		console.log('accounts are : ', User.getAccounts())

		this.setState({ accounts: User.getAccounts() })
	}
	handleInputChange = (event) => {
		const { name, value } = event.target
		console.log(name, value)
		this.state[name] = value
	}
	selectAccount = async (Acc) => {
		console.log(Acc)
		this.context.setLoading_(true)
		let respaccount = await ApiService.post('fairymailer/chooseUserAccount', { account_id: Acc.id }, User.get().jwt)
		console.log(respaccount)
		User.setSelectedAccount(Acc)
		setTimeout(() => {
			window.location.href = '/dashboard'
			this.context.setLoading_(false)
		}, 1200)
	}
	render() {
		const { accounts } = this.state
		return (
			<div>
				<div className="d-flex align-items-center auth px-0">
					<div className="row w-100 mx-0">
						<div className="col-lg-4 mx-auto" style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column' }}>
							<p>Hello {User.get().user.name},</p>
							<h3>Pick an Account:</h3>
							{accounts && accounts.length > 0
								? accounts.map((acc) => {
										return (
											<div className="card text-left py-5 px-4 px-sm-5 mt-10" key={acc.id} onClick={() => this.selectAccount(acc)}>
												<img src={require('../../assets/images/cf-logo-mini.webp')} alt="logo" />
												<h6 className="font-weight-light">{acc.name}</h6>
											</div>
										)
								  })
								: 'No accounts associated with your account :('}
							<br></br>
							<br></br>
							<a
								style={{ paddingBottom: '50px' }}
								className="btn btn-primary-outline"
								onClick={() => {
									User.logout()
									window.location.href = '/login'
								}}
							>
								Logout
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AccountChooser
