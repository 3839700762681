import React, { Component, useEffect } from 'react'
import User from '../services/User'
import { ApiService } from '../services/ApiService'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { v4 as uuidv4 } from 'uuid'
import { Pagination } from 'react-bootstrap'
import { useParams, withRouter } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import LoadingContext from '../ToggleContext'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const mSwal = withReactContent(Swal)
function withParams(Component) {
	return (props) => <Component {...props} params={useParams()} />
}
export class AllCampaigns extends Component {
	static contextType = LoadingContext

	constructor(props) {
		super(props)
		this.state = {
			account: {},
			search_term: '',
			loading: true,
			itemsPerPage: 20,
			currentPage: 1,
			skeletons: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
			campaigns: [],
			cmpStatus: 'sent',
			meta: [],
			error: null,
			domain_verified: true,
			dkim_verified: true,
			email_verified: true,
			loading: true,
		}
	}

	componentDidMount() {
		let cmpStatus = this.props.params.cmpType
		this.setState({ cmpStatus: cmpStatus, loading: false })
		if (cmpStatus && cmpStatus == 'drafts') this.getCampaigns('draft')
		else if (cmpStatus && cmpStatus == 'outbox') this.getCampaigns('outbox')
		else this.getCampaigns()
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.location.reload()
		}
	}

	async updateSearchTerm(search) {
		this.setState({ search_term: search })
		if (this.state.search_term.length >= 3) {
			let user = User.get()
			this.context.setLoading_(true)
			let outboxfilter = this.props.params.cmpType == 'outbox' ? '&filters[date][$notNull]=true' : this.props.params.cmpType == 'drafts' ? '&filters[date][$null]=true' : ''

			let resp = await ApiService.get(
				`fairymailer/getCampaigns?filters[name][$contains]=${search}&filters[account]=${this.state.account.id}&filters[status]=${
					this.props.params.cmpType == 'outbox' ? 'draft' : this.props.params.cmpType == 'drafts' ? 'draft' : this.props.params.cmpType
				}${outboxfilter}&populate[recp_groups][populate][subscribers][count]=true&pagination[pageSize]=100&pagination[page]=1`,
				user.jwt
			)
			console.log('cmps', resp)
			this.setState({ campaigns: resp.data.data, meta: resp.data.meta })
			this.context.setLoading_(false)
		}
	}

	async getCampaigns(status = 'sent', page = 1) {
		this.context.setLoading_(true)
		let user = User.get()

		let account = await ApiService.get(`fairymailer/getAccount`, user.jwt)
		account = account.data.user.account
		console.log(account)
		if (account) {
			this.setState({ account: account, domain_verified: account.domain_verified, email_verified: account.email_verified, dkim_verified: account.dkim_verified })
		}
		let outboxfilter = status == 'outbox' ? '&filters[date][$notNull]=true' : status == 'draft' ? '&filters[date][$null]=true' : ''
		let resp = await ApiService.get(
			`fairymailer/getCampaigns?filters[account]=${account.id}&filters[status]=${
				status == 'outbox' ? 'draft' : status
			}${outboxfilter}&sort[sent_at]=desc&populate[recp_groups][populate][subscribers][count]=true&pagination[pageSize]=${this.state.itemsPerPage}&pagination[page]=${page}`,
			user.jwt
		)
		console.log('cmps', resp)
		this.setState({ campaigns: resp.data.data, meta: resp.data.meta })
		this.context.setLoading_(false)
		this.setState({ loading: false })
		this.context.setLoading_(false)
	}

	async viewMetrics(uuid) {
		window.location.href = `/campaigns/${uuid}/results`
	}
	async deleteCampaign(uuid) {
		mSwal
			.fire({
				icon: 'question',
				text: 'Are you ruse you want to delete this campaign? This action is irriversible.',
				focusCancel: true,
				showConfirmButton: false,
				showDenyButton: true,
				showCancelButton: true,
				denyButtonText: 'Yes, DELETE it',
				cancelButtonText: 'No, abort',
			})
			.then(async (res) => {
				if (res.isDenied) {
					this.context.setLoading_(true)
					let user = await User.get()
					let sendResp = await ApiService.post(`fairymailer/removeCampaign`, { data: { udid: uuid } }, user.jwt)
					this.context.setLoading_(false)
					mSwal.fire({ icon: 'success', timer: 1000 })
					window.location.href = '/campaigns/drafts'
				}
			})
	}
	async sendTest(campaign_id) {
		mSwal
			.fire({
				icon: 'question',
				text: 'Send a test of this campaign to your self?',
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: 'Yes, send it',
				cancelButtonText: 'No, abort',
			})
			.then(async (res) => {
				if (res.isConfirmed) {
					this.context.setLoading_(true)
					let user = await User.get()
					let sendResp = await ApiService.post(`custom/sendTestDraft`, { campaign_id: campaign_id, account_id: this.state.account.id }, user.jwt)
					this.context.setLoading_(false)
					mSwal.fire({ icon: 'success', timer: 1000 })
					console.log(sendResp)
				}
			})
	}
	async editCmp(campaign_id) {
		window.location.href = `/campaigns/editor/${campaign_id}`
	}
	async duplicateCmp(campaign) {
		let user = User.get()
		let account = await ApiService.get(`fairymailer/getAccount`, user.jwt)
		account = account.data.user.account
		mSwal
			.fire({
				title: 'New Campaign name:',
				input: 'text',
				inputValue: `Copy of ${campaign.name}`,
				inputAttributes: {
					autocapitalize: 'off',
				},
				showCancelButton: true,
				confirmButtonText: 'Duplicate',
				showLoaderOnConfirm: true,
				preConfirm: async (input) => {
					try {
						delete campaign.id
						campaign.uuid = uuidv4()
						campaign.name = `${input}`
						campaign.date = null
						campaign.stats = null
						campaign.account = account.id
						campaign.recipients = 0
						campaign.uuid_b = uuidv4()
						campaign.status = 'draft'
						campaign.recp_groups = campaign.recp_groups.map((g) => {
							return g.id
						})
						let resp = await ApiService.post(
							'campaigns/',
							{
								data: campaign,
							},
							user.jwt
						)
						console.log(resp)
						return resp
					} catch (error) {
						Swal.showValidationMessage(`
              Request failed: ${error}
            `)
					}
				},
				allowOutsideClick: false,
			})
			.then((result) => {
				if (result.value && result.value.data.data && result.value.data.data.id) {
					mSwal.fire({ icon: 'success', timer: 1200, text: `Campaign "${result.value.data.data.attributes.name}" created!` }).then(() => {
						window.location.reload()
					})
				}
				console.log('result', result)
			})
	}
	async editCmpDetails(campaign_id) {
		window.location.href = `/campaigns/${campaign_id}/edit`
	}

	fixTimezone(utcDateStr) {
		// return utcDateStr.replace('T',' ').split(':').filter((item,i)=>{if(i<2) return item;}).join(':')
		const utcDate = new Date(utcDateStr)

		// Use Intl.DateTimeFormat to convert to Europe/Athens timezone
		// const athensTimeFormatter = new Intl.DateTimeFormat('en-GB', {
		//     timeZone: 'Europe/Athens',
		//     year: 'numeric',
		//     month: '2-digit',
		//     day: '2-digit',
		//     hour: '2-digit',
		//     minute: '2-digit',
		//     second: '2-digit',
		//     hour12: false,
		// });

		// const [
		//   { value: day }, ,
		//   { value: month }, ,
		//   { value: year }, ,
		//   { value: hour }, ,
		//   { value: minute }
		// ] = athensTimeFormatter.formatToParts(utcDate);
		// const athensTimeStr = `${year}-${month}-${day} ${hour}:${minute}`;
		const athensTimeStr = `${utcDate.getFullYear()}-${(utcDate.getMonth() + 1).toString().padStart(2, '0')}-${utcDate
			.getDate()
			.toString()
			.padStart(2, '0')} ${utcDate.getHours()}:${utcDate.getMinutes()}`
		return athensTimeStr
	}

	render() {
		const { skeletons, campaigns, cmpStatus, domain_verified, dkim_verified, email_verified, meta, error } = this.state
		const { loading } = this.context
		const loaded = loading || this.state.loading
		return (
			<div className="campaigns-container">
				{!domain_verified && !loaded ? (
					<h4 className="alert alert-danger">Warning: Your domain identity is not verified, no emails will be sent from this account until you verify your domain identity.</h4>
				) : !dkim_verified && !loaded ? (
					<h4 className="alert alert-warning">
						Warning: Your domain identity is verified, but you also need to add DKIM signature CNAME records otherwise your sent email will be marked as spam.<br></br> Please visit Account
						Settings > Domain & Identity.
					</h4>
				) : !email_verified && !loaded ? (
					<h4 className="alert alert-warning">
						Warning: Your domain identity is verified, but you also need to verify your MAIL FROM address.<br></br> Please visit Account Settings > Domain & Identity.
					</h4>
				) : (
					''
				)}
				<div className="row">
					<div className="col-sm-12 grid-margin stretch-card">
						<div className="card">
							<div className="card-body">
								<div className="card-title">
									{cmpStatus != 'drafts' ? (
										cmpStatus != 'outbox' ? (
											<div>
												<h2 className="">Sent Campaigns</h2>
											</div>
										) : (
											<div>
												<h2 className="">Outbox</h2>
											</div>
										)
									) : (
										<div>
											<h2 className="">Draft Campaigns</h2>
										</div>
									)}
									<button
										type="button"
										className="btn btn-success"
										onClick={() => {
											window.location.href = '/campaigns/new'
										}}
									>
										<i className="mdi mdi-message-plus"></i> New
									</button>
								</div>
								<div>
									<Form.Group>
										<label htmlFor="cmpSearch">Search</label>
										<input
											type="text"
											name=""
											placeholder="Type at least 3 characters to search"
											className="form-control form-control-lg"
											id="cmpSearch"
											value={this.state.search_term}
											onChange={(evt) => this.updateSearchTerm(evt.target.value)}
										/>
									</Form.Group>
								</div>
								<div className="table-responsive">
									<table className="table table-bordered table-striped">
										<thead>
											<tr>
												<th>Campaign</th>
												<th>Recipients</th>
												<th>Type</th>
												{cmpStatus != 'drafts' && cmpStatus != 'outbox' ? <th>Sent at</th> : ''}
												{cmpStatus == 'outbox' ? <th>To be sent:</th> : ''}
												{cmpStatus != 'drafts' ? <th>Open Rate %</th> : ''}
												{cmpStatus != 'drafts' ? <th>Click Rate %</th> : ''}
												<th></th>
											</tr>
										</thead>
										<tbody>
											{!this.state.loading
												? campaigns.map((cmp, index) => {
														// let openRate = cmp.stats ? cmp.stats.or
														if (cmp.stats && cmp.type === 'absplit') {
															console.log('stats arae : ', cmp)
															cmp.stats.o = cmp.stats.o ? cmp.stats.o : 0
															cmp.stats.ob = cmp.stats.ob ? cmp.stats.ob : 0
															cmp.stats.c = cmp.stats.c ? cmp.stats.c : 0
															cmp.stats.cb = cmp.stats.cb ? cmp.stats.cb : 0
														}

														return (
															<tr key={cmp.id}>
																<td>{cmp.name}</td>
																<td>{cmp.recipients ? cmp.recipients : 'n/a'}</td>
																<td>{cmp.type ? (cmp.type == 'absplit' ? 'A/B Split' : 'Normal') : 'n/a'}</td>
																{cmpStatus != 'drafts' && cmpStatus != 'outbox' ? <td>{cmp.sent_at ? this.fixTimezone(cmp.sent_at) : 'n/a'}</td> : ''}
																{/* {.replace('T',' ').split(':').filter((item,i)=>{if(i<2) return item;}).join(':')} */}
																{cmpStatus == 'outbox' ? <td>{cmp.date ? this.fixTimezone(cmp.date) : 'n/a'}</td> : ''}
																{cmpStatus != 'drafts' ? (
																	<td>
																		{cmp.type === 'absplit'
																			? (((cmp.stats?.o + cmp.stats?.ob) / cmp.recipients) * 100).toFixed(2) + '%'
																			: cmp.stats
																			? `${cmp.stats.or}%`
																			: `-- %`}
																	</td>
																) : (
																	''
																)}
																{/* <td> {cmp.stats ? `${cmp.stats.cr}%` : `-- %`}</td> */}
																{cmpStatus != 'drafts' ? (
																	<td>
																		{cmp.type === 'absplit'
																			? (((parseInt(cmp.stats?.c) + parseInt(cmp.stats?.cb)) / cmp.recipients) * 100).toFixed(2) + '%'
																			: cmp.stats
																			? `${cmp.stats.cr}%`
																			: `-- %`}
																	</td>
																) : (
																	''
																)}
																<td>
																	{cmp.status && cmp.status != 'draft' ? (
																		<div>
																			<Tooltip id="tooltip-duplicate" />
																			<button
																				type="button"
																				className="btn btn-secondary"
																				data-tooltip-id="tooltip-duplicate"
																				data-tooltip-place="top"
																				data-tooltip-content="Duplicate campaign"
																				onClick={() => {
																					this.duplicateCmp(cmp)
																				}}
																			>
																				<i className="mdi mdi-content-copy"></i>
																			</button>{' '}
																			&nbsp;
																			<Tooltip id="tooltip-show-results" />
																			<button
																				type="button"
																				className="btn btn-primary"
																				data-tooltip-id="tooltip-show-results"
																				data-tooltip-place="top"
																				data-tooltip-content="Show Results"
																				onClick={() => {
																					this.viewMetrics(cmp.uuid)
																				}}
																			>
																				<i className="mdi mdi-percent"></i>
																			</button>{' '}
																			&nbsp;
																			<Tooltip id="tooltip-show-body" />
																			<button
																				type="button"
																				className="btn btn-secondary"
																				data-tooltip-id="tooltip-show-body"
																				data-tooltip-place="top"
																				data-tooltip-content="Show Email in Browser"
																				onClick={() => {
																					window.open(`https://fairymail.cobaltfairy.com/api/fairymailer/load-campaign-body/${cmp.uuid}`)
																				}}
																			>
																				<i className="mdi mdi-eye"></i>
																			</button>
																		</div>
																	) : (
																		<div>
																			<button
																				type="button"
																				className="btn btn-warning"
																				onClick={() => {
																					this.editCmpDetails(cmp.uuid)
																				}}
																			>
																				<i className="mdi mdi-grease-pencil"></i>
																			</button>{' '}
																			&nbsp;
																			<button
																				type="button"
																				className="btn btn-secondary"
																				onClick={() => {
																					this.duplicateCmp(cmp)
																				}}
																			>
																				<i className="mdi mdi-content-copy"></i>
																			</button>{' '}
																			&nbsp;
																			<button
																				type="button"
																				className="btn btn-primary"
																				onClick={() => {
																					this.sendTest(cmp.id)
																				}}
																			>
																				<i className="mdi mdi-play"></i>
																			</button>{' '}
																			&nbsp;
																			<button
																				type="button"
																				className="btn btn-danger"
																				onClick={() => {
																					this.deleteCampaign(cmp.uuid)
																				}}
																			>
																				<i className="mdi mdi-trash-can-outline"></i>
																			</button>
																		</div>
																	)}
																</td>
															</tr>
														)
												  })
												: skeletons.map((cmp, index) => {
														return (
															<tr>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
															</tr>
														)
												  })}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Pagination>
					{Array.from({ length: this.state.meta.pagination?.pageCount }, (_, i) => i + 1).map((num) => {
						return (
							<Pagination.Item
								key={num}
								active={num === parseInt(this.state.meta.pagination?.page)}
								onClick={() => {
									this.getCampaigns(this.state.cmpStatus && this.state.cmpStatus == 'drafts' ? 'draft' : 'sent', num)
								}}
							>
								{num}
							</Pagination.Item>
						)
					})}
				</Pagination>
			</div>
		)
	}
}

export default withParams(AllCampaigns)
