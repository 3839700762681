import React, { Component, useEffect, useState } from 'react'
import User from '../services/User'
import { ApiService } from '../services/ApiService'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import LoadingContext from '../ToggleContext'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const mSwal = withReactContent(Swal)
function withParams(Component) {
	return (props) => <Component {...props} params={useParams()} />
}
export class AllAutomations extends Component {
	static contextType = LoadingContext
	constructor(props) {
		super(props)
		this.state = {
			skeletons: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
			automations: [],
			currentPage: 1,
			itemsPerPage: 20,
			autoStatus: '',
			meta: [],
			error: null,
		}
	}

	componentDidMount() {
		// let autoStatus = this.props.params.autoType;
		// this.setState({autoStatus:autoStatus})
		// if(autoStatus && autoStatus=="drafts") this.getautomations(true);
		// else
		this.getAutomations()
	}

	async getAutomations(page = 1) {
		let user = User.get()
		let resp = await ApiService.get(`fairymailer/getAutomations?populate=*&pagination[page]=${page}&pagination[pageSize]=${this.state.itemsPerPage}`, user.jwt)
		if (resp.data && resp.data.data) {
			this.setState({ automations: resp.data.data, meta: resp.data.meta })
		} else {
			mSwal.fire({ icon: 'error', text: 'Failed to retrieve automations list.' })
		}
		this.context.setLoading_(false)
	}

	async editauto(uuid) {
		window.location.href = `/automations/edit/${uuid}`
	}
	async deleteAuto(uuid) {
		mSwal.fire({ icon: 'question', text: 'Are you sure you want to delete this automation? This action cannot be undone.', showCancelButton: true, focusCancel: true }).then(async (res) => {
			if (res.value) {
				mSwal.fire({
					didOpen: async () => {
						Swal.showLoading()
						let res = await ApiService.post('fairymailer/delete-automation', { uuid: uuid }, User.get().jwt)
						console.log(res.data)
						if (res.data && res.data.code && res.data.code == 200) {
							mSwal.fire({ icon: 'success', timer: 1500 })
							this.componentDidMount()
						}
					},
				})
			}
		})
	}

	render() {
		const { skeletons, automations, autoStatus, meta, error } = this.state

		return (
			<div className="automations-container">
				<div className="row">
					<div className="col-sm-12 grid-margin stretch-card">
						<div className="card">
							<div className="card-body">
								<div className="card-title">
									<h2 className="">Automations</h2>
									<button
										type="button"
										className="btn btn-success"
										onClick={() => {
											window.location.href = '/automations/edit/new'
										}}
									>
										<i className="mdi mdi-message-plus"></i> New Automation
									</button>
								</div>
								<div className="table-responsive">
									<table className="table table-bordered table-striped">
										<thead>
											<tr>
												<th>Description</th>
												<th>Status</th>
												<th>Created</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{automations && automations.length > 0
												? automations.map((auto, index) => {
														return (
															<tr>
																<td>{auto.name}</td>
																<td>{auto.active ? 'Active' : 'Inactive'}</td>
																<td>{auto.createdAt.split('T')[0]}</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-warning"
																		onClick={() => {
																			this.editauto(auto.uuid)
																		}}
																	>
																		<i className="mdi mdi-grease-pencil"></i> Edit
																	</button>{' '}
																	&nbsp;
																	{!auto.active ? (
																		<button
																			type="button"
																			className="btn btn-danger"
																			onClick={() => {
																				this.deleteAuto(auto.uuid)
																			}}
																		>
																			<i className="mdi mdi-delete-forever"></i> Delete
																		</button>
																	) : (
																		''
																	)}
																</td>
															</tr>
														)
												  })
												: skeletons.map((auto, index) => {
														return (
															<tr>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
															</tr>
														)
												  })}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withParams(AllAutomations)
