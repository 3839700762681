import React, { Component, useEffect, useState } from 'react'
import Select from 'react-select'

const RcpFilter = ({ id, campaigns, links, onComplete, onDelete, initialKey, initialValue, initialCondition }) => {
	const [key, setKey] = useState(initialKey || null)
	const [condition, setCondition] = useState(initialCondition || null)
	const [value, setValue] = useState(initialValue || null)

	useEffect(() => {
		console.log('keys is : ', key?.value)
		console.log('value is : ', value?.value)
		console.log('condition is : ', condition?.value)

		if (key && key.value && condition && condition.value && value && value.value) {
			let filter = {} // Initialize an empty filter object

			switch (key.value) {
				case 'ocmp':
					filter = {
						$or: [condition.value === 'contains' ? { ocmp_ids: { $contains: value.value } } : { ocmp_ids: { $notContains: value.value } }],
					}
					break
				case 'link':
					filter = {
						$or: [condition.value === 'contains' ? { links_clicked: { $contains: value.value } } : { links_clicked: { $notContains: value.value } }],
					}
					break
				default:
					break
			}

			// Call onComplete with the filter object
			onComplete(id, filter)
		}
	}, [key, value, condition, id])
	return (
		<>
			<small> - And - </small>
			<br></br>
			<div className="rcp-filter-row" key={id}>
				<Select
					options={[
						{ label: 'Link of prev. cmp.', value: 'link' },
						{ label: 'Previous Campaign', value: 'ocmp' },
					]}
					className="select-control"
					value={key}
					onChange={(evt) => {
						setKey(evt)
					}}
				/>
				<Select
					options={key.value == 'ocmp' ? campaigns : links}
					className="select-control"
					value={value}
					onChange={(evt) => {
						setValue(evt)
					}}
				/>
				<Select
					options={[
						{ label: `Was ${key.value == 'ocmp' ? 'opened' : 'clicked'}`, value: 'contains' },
						{ label: `Was NOT ${key.value == 'ocmp' ? 'opened' : 'clicked'}`, value: 'notContains' },
					]}
					className="select-control"
					value={condition}
					onChange={(evt) => {
						setCondition(evt)
					}}
				/>
				<button className="btn btn-danger ml-1" onClick={() => onDelete(id)}>
					X
				</button>
			</div>
		</>
	)
}

export default RcpFilter
