import React, { Component, useEffect, useState } from 'react'
import User from '../services/User'
import { ApiService } from '../services/ApiService'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

import { useParams } from 'react-router-dom'
import LoadingContext from '../ToggleContext'

import { v4 as uuidv4 } from 'uuid'

function withParams(Component) {
	return (props) => <Component {...props} params={useParams()} />
}

export class UserEditor extends Component {
	static contextType = LoadingContext
	constructor(props) {
		super(props)
		this.reset()
	}
	reset() {
		this.state = {
			user: {},
			account_id: 0,
		}
	}

	componentDidMount() {
		let user = User.get()
		let selectedAccount = User.getSelectedAccount()
		if (user.user_role == 'user') {
			window.location.href = '/'
			return
		}
		const usrId = this.props.params.usrId
		if (usrId && usrId.length > 0 && usrId != 'new') this.getUserByGuid(usrId)
		else this.context.setLoading_(false)

		console.log('user is : ', selectedAccount)

		this.setState({ account_id: selectedAccount.id })
	}

	async getUserByGuid(udid = '') {
		let user = User.get()
		let resp = await ApiService.get(`fairymailer/getUsers/?filters[udid]=${udid}&populate=*`, user.jwt)
		this.context.setLoading_(false)
		if (resp.data && resp.data.data) {
			delete resp.data.data[0].password
			resp.data.data[0].role = resp.data.data[0].role.id
			this.setState({ user: resp.data.data[0] })
		}
	}

	updateInputValue(input, evt) {
		let st = this.state.user
		if (input == 'role') {
			st[input] = evt
		} else {
			st[input] = evt.target.value
		}
		this.setState(st)
	}

	async saveUser() {
		this.context.setLoading_(true)
		let user = await User.get()
		let selectedAccount = User.getSelectedAccount()
		let data = this.state.user

		if (data && data.udid && data.udid.length > 0) {
			let resp = await ApiService.put(`users/${data.id}`, data, user.jwt)
			console.log(resp)
			if (resp.data && resp.data.id) {
				window.location.href = `/settings/users`
			}
			return
		}
		data.role = data.role.value
		data.account = selectedAccount.id
		data.confirmed = true

		console.log('user is : ', user)
		console.log('data is : ', data)

		// return

		let resp = await ApiService.post(`users`, data, user.jwt)
		console.log(resp)
		if (resp.data && resp.data.id) {
			window.location.href = `/settings/users`
		}
	}

	render() {
		const { user } = this.state

		return (
			<div className="campaign-editor-container">
				<div className="row">
					<div className="col-sm-12 grid-margin stretch-card">
						<div className="card">
							<div className="card-body">
								<div className="card-title">
									<h2 className="">
										{user && user.id ? (
											<span>
												<small>Editing</small> {user.name} {user.surname}
											</span>
										) : (
											<span>New User</span>
										)}
									</h2>
								</div>
								<div className="form-group row">
									<Form.Group className="col-xs-12 col-md-4">
										<label htmlFor="usrName">First Name</label>
										<input
											type="text"
											name=""
											className="form-control form-control-lg"
											id="usrName"
											value={this.state.user.name}
											onChange={(evt) => this.updateInputValue('name', evt)}
										/>
									</Form.Group>
									<Form.Group className="col-xs-12 col-md-4">
										<label htmlFor="surname">Last Name</label>
										<input
											type="text"
											name=""
											className="form-control form-control-lg"
											id="surname"
											value={this.state.user.surname}
											onChange={(evt) => this.updateInputValue('surname', evt)}
										/>
									</Form.Group>
									<Form.Group className="col-xs-12 col-md-4">
										<label htmlFor="username">Username</label>
										<input
											type="text"
											name=""
											className="form-control form-control-lg"
											id="username"
											value={this.state.user.username}
											onChange={(evt) => this.updateInputValue('username', evt)}
										/>
									</Form.Group>
									<Form.Group className="col-xs-12 col-md-4">
										<label htmlFor="email">E-mail</label>
										<input
											type="email"
											name=""
											className="form-control form-control-lg"
											id="email"
											value={this.state.user.email}
											onChange={(evt) => this.updateInputValue('email', evt)}
										/>
									</Form.Group>
									<Form.Group className="col-xs-12 col-md-4">
										<label htmlFor="password">Password</label>
										<input
											type="password"
											name=""
											className="form-control form-control-lg"
											id="password"
											value={this.state.user.password}
											onChange={(evt) => this.updateInputValue('password', evt)}
										/>
									</Form.Group>
									<div className="col-xs-12 col-md-4">
										<label htmlFor="roleSelect">User Role</label>
										<Select
											options={[
												{ label: 'Choose', value: 2 },
												{ label: 'Admin', value: 4 },
												{ label: 'User', value: 5 },
											]}
											theme={(theme) => ({
												...theme,
												colors: {
													...theme.colors,
													text: 'orangered',
													primary25: '#0090e7',
													primary: 'black',
												},
											})}
											className="form-control form-control-lg"
											id="roleSelect"
											value={this.state.user.role}
											onChange={(evt) => this.updateInputValue('role', evt)}
										/>
									</div>
									<div className="campaign-buttons">
										{/* <button className="btn btn-lg btn-primary" onClick={()=>{this.automation()}}>Create Automation</button> */}
										<button
											className="btn btn-lg btn-success"
											onClick={() => {
												this.saveUser()
											}}
										>
											Save User
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withParams(UserEditor)
