import React, { Component, useEffect, useState } from 'react'
import User from '../services/User'
import { ApiService } from '../services/ApiService'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingContext from '../ToggleContext'
import { Pagination } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const mSwal = withReactContent(Swal)

export class Templates extends Component {
	static contextType = LoadingContext
	constructor(props) {
		super(props)
		this.state = {
			skeletons: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
			itemsPerPage: 20,
			currentPage: 1,
			templates: [],
			meta: [],
			error: null,
		}
	}

	componentDidMount() {
		this.getTemplates()
	}

	async editCmpDetails(campaign_id) {
		window.location.href = `/templates/edit/${campaign_id}`
	}

	async getTemplates(page = 1) {
		let user = User.get()
		let resp = await ApiService.get(`fairymailer/getTemplates?pagination[pageSize]=${this.state.itemsPerPage}&pagination[page]=${page}`, user.jwt)
		if (resp.data && resp.data.data) {
			this.setState({ templates: resp.data.data, meta: resp.data.meta })
		}
		this.context.setLoading_(false)
	}

	async deleteTemplate(uuid) {
		mSwal
			.fire({
				icon: 'question',
				text: 'Are you ruse you want to delete this template? This action is irriversible.',
				focusCancel: true,
				showConfirmButton: false,
				showDenyButton: true,
				showCancelButton: true,
				denyButtonText: 'Yes, DELETE it',
				cancelButtonText: 'No, abort',
			})
			.then(async (res) => {
				console.log('res is : ', res)
				if (res.isDenied) {
					this.context.setLoading_(true)
					let user = await User.get()
					//Custom Call here.
					// let sendResp = await ApiService.post(`fairymailer/removeCampaign`, { data: { udid: uuid } }, user.jwt)
					this.context.setLoading_(false)
					mSwal.fire({ icon: 'success', timer: 1000 })
					// window.location.href = '/templates/all/'
				}
			})
	}

	cleanDate(date) {
		date = date.split('T')
		return `${date[0]} ${date[1].split(':')[0] + ':' + date[1].split(':')[1]}`
	}
	render() {
		const { skeletons, templates, meta, error } = this.state

		return (
			<div className="templates-container">
				<div className="row">
					<div className="col-sm-12 grid-margin stretch-card">
						<div className="card">
							<div className="card-body">
								<div className="card-title">
									<h2 className="">Templates</h2>
									<button
										type="button"
										className="btn btn-success"
										onClick={() => {
											window.location.href = '/templates/edit/new'
										}}
									>
										<i className="mdi mdi-message-plus"></i> New editor
									</button>{' '}
									&nbsp;
								</div>
								<div className="table-responsive">
									<table className="table table-bordered table-striped">
										<thead>
											<tr>
												<th>Template name</th>
												<th>Created at</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{templates && templates.length > 0
												? templates.map((cmp, index) => {
														return (
															<tr>
																<td>{cmp.name}</td>
																<td>{this.cleanDate(cmp.createdAt)}</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-warning"
																		onClick={() => {
																			this.editCmpDetails(cmp.uuid)
																		}}
																	>
																		<i className="mdi mdi-pencil"></i> Edit
																	</button>
																	&nbsp;
																	<button
																		type="button"
																		className="btn btn-primary"
																		onClick={() => {
																			window.open(`https://fairymail.cobaltfairy.com/api/fairymailer/load-campaign-body/${cmp.uuid}`)
																		}}
																	>
																		<i className="mdi mdi-eye"></i> Preview
																	</button>
																	&nbsp;
																	<button
																		type="button"
																		className="btn btn-danger"
																		onClick={() => {
																			this.deleteTemplate(cmp.uuid)
																			// window.open(`https://fairymail.cobaltfairy.com/api/fairymailer/load-campaign-body/${cmp.uuid}`)
																		}}
																	>
																		<i className="mdi mdi-delete"></i> Delete
																	</button>
																</td>
															</tr>
														)
												  })
												: skeletons.map((cmp, index) => {
														return (
															<tr>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
																<td>
																	<Skeleton />{' '}
																</td>
															</tr>
														)
												  })}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<Pagination>
						{Array.from({ length: this.state.meta.pagination?.pageCount }, (_, i) => i + 1).map((num) => {
							return (
								<Pagination.Item
									key={num}
									active={num === parseInt(this.state.meta.pagination?.page)}
									onClick={() => {
										this.getTemplates(num)
									}}
								>
									{num}
								</Pagination.Item>
							)
						})}
					</Pagination>
				</div>
			</div>
		)
	}
}

export default Templates
